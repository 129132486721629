<template>
  <div class="actions">
    <button class="btn btn-sm btn-outline-success btn-block"
            type="button"
            @click="itemAction('edit-item', rowData, rowIndex)">
      <i class="fas fa-edit mr-1"></i> Edit
    </button>
    <button class="btn btn-sm btn-outline-danger  btn-block"
            type="button"
            @click="itemAction('delete-item', rowData, rowIndex)">
      <i class="far fa-trash-alt mr-1"></i> Delete
    </button>
    <button class="btn btn-sm btn-block"
        :class="rowData.startSchedule !== '' && rowData.endSchedule !== '' ?
          'btn-outline-secondary' : 'btn-outline-primary'"
        @click="itemAction('send-item', rowData, rowIndex)"
        type="button"
        :disabled="rowData.startSchedule === '' || rowData.endSchedule === ''">
    <i class="far fa-bell mr-1"></i> Send
    </button>
    <!-- <button class="btn btn-sm btn-block"
        :class="rowData.startSchedule !== '' && rowData.endSchedule !== '' ?
          'btn-outline-secondary' : 'btn-outline-primary'"
        @click="itemAction('send-item', rowData, rowIndex)"
        v-if="rowData.status === 'Unsent'"
        type="button"
        :disabled="rowData.startSchedule === '' || rowData.endSchedule === ''">
    <i class="far fa-bell mr-1"></i> Send
    </button> -->
    <!-- <button class="btn btn-sm btn-primary btn-block"
            type="button"
            v-if="rowData.status === 'Sent'">
      <i class="far fa-bell mr-1"></i> Sent
    </button> -->
    <!-- <button class="btn btn-sm btn-block"
            :class="rowData.startSchedule !== '' && rowData.endSchedule !== '' ?
              'btn-outline-secondary' : 'btn-outline-primary'"
            @click="itemAction('send-item', rowData, rowIndex)"
            v-if="rowData.status === 'Unsent'"
            type="button"
            :disabled="rowData.startSchedule !== '' &&
              rowData.endSchedule !== ''">
      <i class="far fa-bell mr-1"></i> Send {{ rowData.startSchedule }}
    </button>
    <button class="btn btn-sm btn-primary btn-block"
            type="button"
            v-if="rowData.status === 'Sent'">
      <i class="far fa-bell mr-1"></i> Sent
    </button> -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             :opacity="0.5"
             :width="70"
             :height="70"
             background-color="#000"
             color="#068bcd"
    ></loading>
  </div>
</template>


<script>
  const EditAnnouncement = () => import('./EditAnnouncement.vue');
  const SendAnnouncement = () => import('./SendAnnouncement.vue');
  import moment from 'moment-timezone';
  const Loading = () => import('vue-loading-overlay');
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    isLoading : false,
    name  : 'AnnouncementListActions',
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    components : {
      Loading,
    },
    methods : {
      itemAction(action, data, index) {
        if (action === 'edit-item') {
          this.$modal.show(EditAnnouncement, {
            data : data,
          }, {
            height   : 'auto',
            adaptive : true,
          }, {
            'reload-table' : (event) => {
              this.$parent.reload();
            },
          });
        } else if (action === 'delete-item') {
          this.$modal.show('dialog', {
            title   : 'Please confirm:',
            text    : `Are you sure you want to delete <b>${data.title}</b>?`,
            buttons : [ {
              title   : 'Yes',
              handler : () => {
                this.$events.fire('loading-state', true);
                this.$http.delete('api/announcement', {
                  data : {
                    'announcementId' : data.announcementId,
                  },
                }).then(() => {
                  this.$events.fire('loading-state', false);
                  this.$events.fire('reload');
                  this.$parent.reload();
                  this.$modal.hide('dialog');
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Deleted announcement successfully.',
                  });
                }).catch(() => {
                  this.$events.fire('loading-state', false);
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                });
              },
            }, {
              title : 'No',
            } ],
          });
        } else if (action === 'send-item') {
          
          this.isLoading = true;

        // set default time zone
        // moment.tz.setDefault(data.timezone);
        // const startDate = new Date();
        const timezone = data.timezone || 'UTC';

        // Establecer la zona horaria predeterminada
        moment.tz.setDefault(timezone);

        // Obtener la fecha y hora actual
        const startDate = new Date();

        // Formatear la fecha y hora en la zona horaria especificada
        const formattedDate = moment(startDate).tz(timezone).format('YYYY-MM-DD HH:mm');

        this.$http.post('api/announcement/send', {
          startDate : moment(startDate).tz(timezone).format('YYYY-MM-DD HH:mm'),
          endDate        : data.endDate + ' ' + data.endTime,
          timezone       : data.timezone,
          announcementId : data.announcementId,
          platform       : data.platform,
          content        : data.content,
          filter         : data.filter,
          title          : data.title,
          link           : data.link,
          mediaType      : data.mediaType,
          playlistId     : data.playlist,
        }).then(() => {
          this.$parent.$emit('reload-table');
          this.$emit('close');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Added Announcement successfully!',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.isLoading = false;
          this.$parent.reload();
        });
          // this.$modal.show(SendAnnouncement, {
          //   data : data,
          // }, {
          //   height   : 'auto',
          //   adaptive : true,
          // }, {
          //   'reload-table' : (event) => {
          //     this.$parent.reload();
          //   },
          // });
        }
      },
    },
  }
</script>

<style lang="scss">
@import
  "../../../assets/scss/components/admin/announcement/announcement-list-actions"
</style>